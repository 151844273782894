import cx from 'classnames';
import PostBody from 'components/post/PostBody';
import GlobalsContext from 'contexts/GlobalsContext';
import _get from 'lodash/get';
import { useContext } from 'react';
import styles from './Partners.module.scss';

const Members = ({ content }) => {
  const num = Math.ceil(content.length / 4);
  const cols = [...Array(4)];
  const items = cols.map((col, i) =>
    content.slice(i * num, (i + 1) * num),
  );

  return items.map((col, i) => (
    <div className="col-12 col-md-3" key={i}>
      {col.map((item, k) => (
        <a
          key={k}
          className={styles.bodyText}
          href={item.postPartners.url}
          target="_blank"
          rel="noreferrer"
        >
          {item.title}
        </a>
      ))}
    </div>
  ));
};

const Partners = (props) => {
  // which of the partners we'll show
  let partners = props.partners || []; // 'partner' (steering), 'national', etc.
  let partnerType = props.partnerType || 'partner'; // 'steering', 'national', etc.

  // filtering the list by a selected partnerType?
  if (partnerType !== 'all') {
    partners = partners.filter(
      (item) => item.postPartners.partnerType === partnerType,
    );
  }

  let ourPartners = partners.sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  const globals = useContext(GlobalsContext);
  let language = _get(globals, 'language', 'en');

  // let partnersGroup = _get(
  //   globals,
  //   `acfThemeSettingsByLanguage.acfOptionsThemeSettingsByLanguage.${language}.partnersgroup`,
  //   {},
  // );

  return (
    <div className={styles.partnersContainer} id="partners">
      <div className="container">
        <div className="row">
          {props.sectionTitle && (
            <div className={styles.headingText}>
              {props.sectionTitle}
            </div>
          )}

          {props.blurb && (
            <div className="mt-2 mt-md-4 col-12 col-md-8 offset-md-2">
              <div className="wysiwyg-content">
                <PostBody content={props.blurb} />
              </div>
            </div>
          )}
        </div>
        <div className={cx('row', styles.contentRow)}>
          {ourPartners && <Members content={ourPartners} />}
        </div>
      </div>
    </div>
  );
};

export default Partners;
