import cx from 'classnames';
import { FaArrowCircleDown, FaPencilAlt } from 'react-icons/fa';
import styles from './SignupBar.module.scss';

const SignupBar = ({ btnLabel = 'Add Your Name' }) => {
  return (
    <div className={cx(styles.signupBar)}>
      <div className="row">
        <div className="col-lg-12">
          <div className={cx(styles.barInner)}>
            <a
              href="#form-column"
              className={cx('btn btn-red btn-sm', styles.btn)}
            >
              <span>
                {btnLabel}
                <FaArrowCircleDown size="1.4em" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupBar;
