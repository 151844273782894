import analytics from 'lib/analytics';
import UTM from 'lib/utm';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const EventForm = ({ formURL, children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const buttonShim = (element, color) => {
    const elementParentId = element.id + 'Parent';
    if (document.getElementById(elementParentId)) return;
    const parent = element.parentNode;
    const wrapper = document.createElement('span');
    wrapper.id = elementParentId;
    wrapper.classList.add('btn', 'btn-sm', `btn-${color}`);
    parent.replaceChild(wrapper, element);
    wrapper.appendChild(element);
    element.classList.add('btn', 'btn-sm', `btn-${color}`);
  };

  useEffect(() => {
    const formScript = document.getElementById('formEmbedScript');

    if (formScript) {
      formScript.remove();
      delete window.nvtag;
      window.nvtag_callbacks = [];
    }

    window.nvtag_callbacks = window.nvtag_callbacks || {};
    const nvtag_callbacks = window.nvtag_callbacks;
    nvtag_callbacks.postRender = nvtag_callbacks.postRender || [];
    const formPostLoad = () => {
      const formSubmitBtn = document.querySelector(
        '.at-form .at-submit',
      );
      if (formSubmitBtn) {
        buttonShim(formSubmitBtn, 'red');
        formSubmitBtn.classList.remove('btn-at', 'btn-at-primary');
      }
    };
    nvtag_callbacks.postRender.push(formPostLoad);

    nvtag_callbacks.alterPost = nvtag_callbacks.alterPost || [];

    let utms = {};
    utms = UTM.get();

    nvtag_callbacks.alterPost.push(function (args) {
      args.data.oa_utm_source = utms?.utm_source || '';
      args.data.oa_utm_medium = utms?.utm_medium || '';
      args.data.oa_utm_campaign = utms?.utm_campaign || '';
      args.data.oa_utm_term = utms?.utm_term || '';
      args.data.oa_utm_content = utms?.utm_content || '';
    });

    const submitSegue = function (args) {
      analytics.track('sign_up');
      return args;
    };

    nvtag_callbacks.preSegue = nvtag_callbacks.preSegue || [];
    nvtag_callbacks.preSegue.push(submitSegue);

    const script = document.createElement('script');
    script.id = 'formEmbedScript';
    script.src = 'https://d3rse9xjbp8270.cloudfront.net/at.js';
    document.head.appendChild(script);

    return () => {
      const formScript = document.getElementById('formEmbedScript');

      if (formScript) {
        formScript.remove();
        delete window.nvtag;
        window.nvtag_callbacks = [];
      }
    };
  }, [formURL]);

  return <></>;

  // return (
  //   <div {...props} ref={setContentRef}>
  //     <style jsx global>{`
  //       .at {
  //         font-family: 'Open Sans', sans-serif !important;
  //       }
  //       .intl-phone-MobilePhone {
  //         height: unset !important;
  //         padding-top: 12px !important;
  //         padding-bottom: 12px !important;
  //         padding-right: 12px !important;
  //         border-radius: 0 !important;
  //       }
  //       .at input:not(.intl-phone-MobilePhone, .intl-phone-HomePhone),
  //       .at select,
  //       .at textarea:not(.at-tweet-message) {
  //         border-radius: 0 !important;
  //         padding: 12px !important;
  //         height: unset !important;
  //       }
  //       .at input[type='checkbox']:checked + span:before {
  //         background-color: #e6334f !important;
  //         border-color: #e6334f !important;
  //       }
  //       .at-title,
  //       .at-markup:not(.at-legal) {
  //         display: none !important;
  //       }
  //       .at fieldset legend {
  //         font-family: 'Open Sans', sans-serif !important;
  //         margin: 0 !important;
  //       }
  //       .at .at-fieldset:not(.AdvocacyTweets, .ContactInformation),
  //       .at-fieldset:not(.AdvocacyTweets, .ContactInformation) {
  //         padding: 0 !important;
  //       }
  //       .at label {
  //         color: #262526 !important;
  //       }
  //       .at-row > [class^='at-'] {
  //         margin-left: 0 !important;
  //       }
  //       .at-form-submit {
  //         text-align: center;
  //         margin-top: 15px;
  //       }
  //       .at-form-submit .at-submit {
  //         margin: 0 !important;
  //       }
  //       .at-legend {
  //         display: none;
  //       }
  //       .at .at-step a,
  //       .at .at-step b {
  //         padding: 20px 0 0 !important;
  //         font-size: 0.75rem;
  //       }
  //       .at .at-step a:before,
  //       .at .at-step b:before {
  //         transform: translateY(-100%) translateX(-50%);
  //         left: 50%;
  //         right: auto;
  //       }
  //       .at-advocacy-tweet .at-tweet-area {
  //         width: 100% !important;
  //       }
  //       .at-advocacy-tweet .at-tweet-message {
  //         width: 100% !important;
  //         height: 140px !important;
  //         margin: 5px 5px 5px 0 !important;
  //       }
  //       .at-advocacy-tweet .at-tweet-closed-msg {
  //         width: 100% !important;
  //         margin: 5px 0 0 0 !important;
  //       }
  //       .at-form-submit .back-link {
  //         float: unset !important;
  //         text-decoration: underline !important;
  //       }
  //       .SmsSubscribeMobilePhone {
  //         margin-bottom: 10px;
  //       }
  //       .at-targets {
  //         margin: 0 !important;
  //       }
  //     `}</style>
  //     {contentRef &&
  //       createPortal(
  //         <div
  //           key={formURL}
  //           className="ngp-form"
  //           data-form-url={formURL}
  //           data-fastaction-endpoint="https://fastaction.ngpvan.com"
  //           data-inline-errors="true"
  //           data-fastaction-nologin="true"
  //           data-databag-endpoint="https://profile.ngpvan.com"
  //           data-databag="everybody"
  //           data-mobile-autofocus="false"
  //         ></div>,
  //         contentRef,
  //       )}
  //   </div>
  // );
};

export default EventForm;
