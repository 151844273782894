import sectionOptions from './sectionOptions';

export function flexWysiwygContent(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_WysiwygContent {
      contentWidth
      sectionHeading
      wysiwygContent
      ${sectionOptions}
    }
  `;

  return fragment;
}
