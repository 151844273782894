import sectionOptions from './sectionOptions';

export function flexImageVideoWithContent(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_ImageVideoWithContent {
      bodyText
      headingOptions {
        headingColor
        headingStamp
      }
      headingText
      image {
        sourceUrl
        mediaDetails {
          height
          width
        }
      }
      imageOrVideo
      languageBarOptions {
        englishText
        shareText
        spanishText
      }
      layoutOptions {
        contentOrientation
        imageVideoWidth
        mobileContentPlacement
      }
      shareBar
      shareBarOptions {
        shareText
        shareTweet
        shareUrl
      }
      subheadingText
      video
      videoBar
      videoSpanish
      buttonUrl {
        url
        target
      }
      buttonColor
      buttonSize
      buttonText
      ${sectionOptions}
    }
  `;

  return fragment;
}
