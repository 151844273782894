import sectionOptions from './sectionOptions';

export function flexCallout(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_Callout {
      calloutText
      sectionHeading
      ${sectionOptions}
    }
  `;

  return fragment;
}
