// fields for csv powered partners lookup component
const globalsPartnersLookup = /* GraphQL */ `
  acfGlobalThemeSettings {
    partnersLookup {
      fieldGroupName
      partnersProductionData {
        mediaItemUrl
      }
      partnersTestingData {
        mediaItemUrl
      }
    }
  }
`;

export default globalsPartnersLookup;
