import sectionOptions from './sectionOptions';

export function flexPartners(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_PartnersList {
      sectionHeading
      blurb
      partnerType
      ${sectionOptions}
    }
  `;

  return fragment;
}
