import cx from 'classnames';
import EventForm from 'components/EventForm';
import SignupBar from 'components/SignupBar';
import { HideOn } from 'react-hide-on-scroll';

import styles from './FlexLanding.module.scss';

// Conditional for sticky sign up bar
const enableSignupBar = true;

const FlexLanding = (props) => {
  return (
    <section className="section-padded">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div
            className={cx(
              styles.mapColumn,
              'col-12 col-sm-11 col-md-9 col-lg-8 mt-5',
            )}
            id="eventContainer"
          >
            <div className="wysiwyg-content">
              {props.heading && (
                <h2>
                  <span className="text-orange">{props.heading}</span>
                </h2>
              )}
              {props.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.content,
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-sm-11 col-md-9 col-lg-4 mt-5">
            <div className={styles.formColumn} id="form-column">
              <div>
                {props.formBlurb && <p>{props.formBlurb}</p>}
                {props.everyactionUrl && (
                  <EventForm formURL={props.everyactionUrl} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {enableSignupBar && (
        <HideOn divID="form-column" offset={-600}>
          <SignupBar btnLabel={props.buttonLabel} />
        </HideOn>
      )}
    </section>
  );
};

export default FlexLanding;
