import sectionOptions from './sectionOptions';

export function flexBreadcrumbbar(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_Breadcrumbbar {
      share
      shareEmailBody
      shareEmailSubject
      shareTweet
      shareUrl
      ${sectionOptions}
    }
  `;

  return fragment;
}
