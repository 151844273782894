// Global fields that need translation. Kept in a cloneable Shared field.
// each language has the same fields
const globalLanguageFields = `
  alertBarLink {
    url
    title
    target
  }
  alertBarText
  fieldGroupName
  flexFormWithContentGroup {
    emailLabel
    emailOptin
    fieldGroupName
    phoneNumberLabel
    smsOptin
    zipLabel
  }
  footergroup {
    copyright
    fieldGroupName
  }
  headergroup {
    breadcrumbTopLevelText
    fieldGroupName
  }
  partnersgroup {
    fieldGroupName
    sectionTitle
  }
  sharedefaultsgroup {
    fieldGroupName
    shareTweetText
  }
`;

const globalThemeSettingsByLanguage = /* GraphQL */ `
  acfThemeSettingsByLanguage {
    acfOptionsThemeSettingsByLanguage {
      fieldGroupName
      es {
        ${globalLanguageFields}
      }
      en {
        ${globalLanguageFields}
      }
    }
    pageTitle
    pageSlug
  }
`;

export default globalThemeSettingsByLanguage;
