import { INTERNAL_DOMAINS } from 'lib/constants';

export default function relativeUrlInternal(href = '') {
  let whitelist = INTERNAL_DOMAINS;
  // anchor
  if (href.startsWith('#')) {
    return href;
  }
  // already relative
  if (!href.startsWith('http')) {
    return href;
  }

  let url = new URL(href);

  // client side redirects don't go throught next.config.js
  // harcoding our major changes here. In future, we might need to read from globals redirect object and check
  if (whitelist.includes(url.hostname)) {
    // check for links that need to be rewritten
    let path = url.pathname;
    path = path.replace('/act/', '/');
    return path;
  }

  return href;
}
