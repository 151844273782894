import cx from 'classnames';
import Button from 'components/Button';
import EveryactionEmbed from 'components/EveryActionEmbed';
import GlobalsContext from 'contexts/GlobalsContext';
import _get from 'lodash/get';
import { useContext, useState } from 'react';
import styles from './FlexFormContent.module.scss';

const FlexFormContent = (props) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const submitToggle = () => setFormSubmitted(true);
  const globals = useContext(GlobalsContext);
  let language = _get(globals, 'language', 'en');

  let flexFormWithContentGroup = _get(
    globals,
    `acfThemeSettingsByLanguage.acfOptionsThemeSettingsByLanguage.${language}.flexFormWithContentGroup`,
    {},
  );

  return (
    <div
      className={cx([styles.flexFormContent, props.customClasses])}
    >
      <div className="row equal">
        <div className="col-12 col-lg-12 d-flex justify-content-center pl-0 pr-0">
          <div className={styles.formContentCol}>
            <div className={styles.formContentInner}>
              {props.sectionHeading && (
                <h2
                  className={styles.sectionHeading}
                  dangerouslySetInnerHTML={{
                    __html: props.sectionHeading,
                  }}
                ></h2>
              )}
              {props.sectionContent && (
                <div
                  className={styles.sectionContent}
                  dangerouslySetInnerHTML={{
                    __html: props.sectionContent,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="col-12 col-lg-6 pl-0 pr-0">
          <div className={styles.formEmbedCol}> */}
        {/* <div className={styles.formWrapper}>
              {props.formHeading && !formSubmitted && (
                <h2 className={styles.formHeading}>
                  {props.formHeading}
                </h2>
              )}
              {!formSubmitted && (
                <div className="signup-form fired-up-embed">
                  <EveryactionEmbed
                    onSubmit={submitToggle}
                    formButtonText={props.formButtonText}
                    formURL={
                      props.everyactionUrl ||
                      'https://secure.everyaction.com/v1/Forms/j5Er513x70Sb-9hIINtsyg2'
                    }
                  />
                </div>
              )}
              {formSubmitted && (
                <>
                  {props.inlineThankYouTitle && (
                    <h2 className={styles.formHeading}>
                      {props.inlineThankYouTitle}
                    </h2>
                  )}
                  <div className="signup-form fired-up-embed">
                    {props.inlineThankYouContent && (
                      <div
                        className={styles.sectionContent}
                        dangerouslySetInnerHTML={{
                          __html: props.inlineThankYouContent,
                        }}
                      />
                    )}
                    <div className={styles.sectionButton}>
                      <Button
                        color={props.inlineThankYouButton.color}
                        size={props.inlineThankYouButton.size}
                        text={props.inlineThankYouButton.text}
                        link={
                          props.inlineThankYouButton?.url?.url || null
                        }
                        target={
                          props.inlineThankYouButton?.url?.target ||
                          null
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div> */}
        {/* </div>
        </div> */}
      </div>
    </div>
  );
};

export default FlexFormContent;
