import sectionOptions from './sectionOptions';

export function flexEvents(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_Events {
      eventsHeading
      eventsIntroBlurb
      hideFilters
      hideMap
      ${sectionOptions}
    }
  `;

  return fragment;
}
