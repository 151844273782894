import cx from 'classnames';
import GlobalsContext from 'contexts/GlobalsContext';
import { parse } from 'csv-parse/sync';
import Link from 'next/link';
import qs from 'query-string';
import { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import Partners from '../Partners';
import styles from './FlexPartners.module.scss';

const FlexPartners = (props) => {
  // map any custom classes to our SCSS module style object
  let moduleClassNames = props.customClasses?.map((className) => {
    return styles[className];
  });

  const globals = useContext(GlobalsContext);

  return (
    <section
      id={props.slug}
      className={cx([
        props.classNames,
        moduleClassNames,
        styles[props.flexClass],
      ])}
    >
      <Partners
        sectionTitle={props.sectionHeading}
        blurb={props.blurb}
        partners={globals?.partners?.nodes}
        partnerType={props.partnerType || 'all'}
      />
    </section>
  );
};

export default FlexPartners;
