import Flex from 'components/flex/Flex';
import LayoutDefault from 'components/layouts/LayoutDefault';
import PostBody from 'components/post/PostBody';
import { GlobalsProvider } from 'contexts/GlobalsContext';
import checkRedirects from 'lib/checkRedirects';
import { getGlobalProps } from 'lib/wordpress';
import {
  getContent,
  getAllContentWithSlug,
  getPartners,
} from 'lib/wordpress';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

export default function Page({
  flexSections,
  globals,
  partners,
  post,
  preview,
  template,
}) {
  if (template === 'Flex') {
    // hero needs to know if next section is white
    // let bgNext = 'white';
    // if (
    //   flexSections &&
    //   flexSections.length &&
    //   flexSections[0].backgroundColor != 'transparent'
    // ) {
    //   bgNext = flexSections[0].backgroundColor;
    // }

    return (
      <GlobalsProvider globals={globals}>
        <LayoutDefault
          preview={preview}
          title={post?.title}
          variant={post?.variant?.c4Page && 'action'}
          seo={post?.seo}
          postId={post?.databaseId}
          isRevision={post?.isPreview}
          partners={partners}
          translations={post?.translations}
        >
          <Flex sections={flexSections} />
        </LayoutDefault>
      </GlobalsProvider>
    );
  }

  return (
    <GlobalsProvider globals={globals}>
      <LayoutDefault
        preview={preview}
        title={post?.title}
        postId={post?.databaseId}
        isRevision={post?.isPreview}
        variant={post?.variant?.c4Page && 'action'}
        seo={post?.seo}
        translations={post?.translations}
      >
        <section className="section-padded">
          <div className="container">
            <div className="row">
              <div className="col">
                <PostBody content={post?.content} />
              </div>
            </div>
          </div>
        </section>
      </LayoutDefault>
    </GlobalsProvider>
  );
}

export async function getStaticProps({
  params,
  preview = false,
  previewData,
}) {
  let slug = '';
  let uri = '/';

  if (params.slug?.length) {
    slug += params.slug.join('/');
    uri += slug;
  }

  if (uri === '/index') {
    uri = '/home';
  }

  // get global data (cachable query used to check redirects)
  const globals = await getGlobalProps();

  // Check for redirects first
  const redirect = checkRedirects(
    slug,
    globals.allRedirects?.redirects,
  );

  if (
    typeof redirect === 'object' &&
    redirect?.destination &&
    redirect?.statusCode
  ) {
    return { redirect: redirect };
  }

  // workaround for this bug with grabbing a language homepage
  // https://github.com/valu-digital/wp-graphql-polylang/issues/35
  if (uri === '/es' || uri === '/es/') {
    uri = '/es/home';
  }

  // get page level data now that we've exhausted redirects and 404s
  const data = await getContent(uri, preview, previewData);

  // check if news with redirect
  if (data.contentNode?.post_type_news?.externalUrl) {
    return {
      redirect: {
        destination: data.contentNode?.post_type_news?.externalUrl,
        statusCode: 307,
      },
    };
  }

  if (!preview && !data?.contentNode?.slug) {
    return {
      notFound: true,
    };
  }

  // todo: move to global context
  const partners = await getPartners();

  let language = _get(data.contentNode, 'language.slug', 'en');

  let postSkinny = _omit(data.contentNode, [
    'acfPageOptions',
    'template.acfFlex.flexContent',
  ]);

  return {
    props: {
      preview,
      post: postSkinny,
      partners: partners?.edges?.map(({ node }) => node) || null,
      flexSections:
        data.contentNode?.template?.acfFlex?.flexContent || null,
      template: data.contentNode?.template?.templateName || null,
      globals: {
        ...globals,
        language: language,
        pageOptions: data.contentNode?.acfPageOptions || {},
      },
    },
    revalidate: 60,
  };
}

export async function getStaticPaths() {
  const globals = await getGlobalProps();
  // default to the homepage(s)
  let paths = ['/', '/es/home', '/'];

  // add top level ES pages
  if (Array.isArray(globals?.menuHeaderEs?.nodes)) {
    globals.menuHeaderEs.nodes.forEach((item) => {
      if (!item.path.includes('http') && !item.path.includes('#')) {
        paths.push(item.path);
      }
    });
  }

  // add top level EN pages
  if (Array.isArray(globals?.menuHeaderEn?.nodes)) {
    globals.menuHeaderEn.nodes.forEach((item) => {
      if (!item.path.includes('http') && !item.path.includes('#')) {
        paths.push(item.path);
      }
    });
  }

  // const allNews = await getAllContentWithSlug('NEWS');
  // const pathsNews = allNews.nodes.map((node) => `${node.uri}`) || [];

  // console.log(pathsNews);

  // // merge
  // const allPaths = [...paths, ...pathsNews];
  // const uniquePaths = [...new Set(allPaths)];

  return {
    paths: [...paths],
    fallback: 'blocking',
  };
}
