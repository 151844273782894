import sectionOptions from './sectionOptions';

export function flexFeaturedContent(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_FeaturedContent {
      bodyText
      headingText
      layoutOptions {
        columns
        fieldGroupName
        fullWidth
        hideBodyText
      }
      subheadingText
      buttonColor
      buttonSize
      buttonText
      buttonUrl {
        url
        target
        title
      }
      ${sectionOptions}
    }
  `;

  return fragment;
}
