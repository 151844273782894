import cx from 'classnames';
import relativeUrlInternal from 'lib/relativeUrlInternal';
import Link from 'next/link';

const TranslationsNav = ({ language = 'en', translations = [] }) => {
  // if no translation, display link for opposite language to homepage
  if (translations.length === 0) {
    return (
      <nav className={cx('tranlations-nav')}>
        {language == 'en' && (
          <Link href="/es">
            <a className="nav">Español</a>
          </Link>
        )}
        {language == 'es' && (
          <Link href="/">
            <a className="nav">English</a>
          </Link>
        )}
      </nav>
    );
  }

  // else, we have a translations array, use that.
  return (
    <nav className={cx('tranlations-nav')}>
      {translations.map((postTranslation, index) => {
        let link = relativeUrlInternal(postTranslation.link);

        // fixes for home: https://github.com/valu-digital/wp-graphql-polylang/issues/35

        if (link == '/home/') {
          link = '/';
        }
        if (link == '/es/home/') {
          link = '/es';
        }

        return (
          <Link href={link} key={index}>
            <a className="nav">{postTranslation.language.name}</a>
          </Link>
        );
      })}
    </nav>
  );
};

export default TranslationsNav;
