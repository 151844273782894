import fragmentPartnersLookup from './fragments/globalsPartnersLookup';
import globalThemeSettingsByLanguage from './fragments/globalThemeSettingsByLanguage';

const fragmentMenu = /* GraphQL */ `
  nodes {
    id
    databaseId
    label
    parentId
    url
    path
    target
    cssClasses
  }
`;

const fragmentLanguages = /* GraphQL */ `
  languages {
    code
    homeUrl
    locale
    name
    slug
  }
  defaultLanguage {
    name
    code
  }
`;

const redirectsRedirection = /* GraphQL */ `
  redirection {
    redirects {
      origin
      target
      type
      matchType
      statusCode: code
    }
  }
`;

const seoGlobal = /* GraphQL */ `
  seo {
    openGraph {
      defaultImage {
        mediaDetails {
          width
          height
        }
        sourceUrl
      }
    }
  }
`;

const menus = /* GraphQL */ `
  menuHeaderEn: menuItems(where: { location: HEADER }, first: 100) {
    ${fragmentMenu}
  }
  menuHeaderEs: menuItems(where: { location: HEADER___ES }, first: 100) {
    ${fragmentMenu}
  }
  # menuFooter: menuItems(where: { location: FOOTER }, first: 100) {
  #   $ {fragmentMenu}
  # }
  # menuFooterEs: menuItems(where: { location: FOOTER_ES }, first: 100) {
  #   $ {fragmentMenu}
  # }
  menuFooterSocialEn: menuItems(where: { location: FOOTER_SOCIAL }, first: 100) {
    ${fragmentMenu}
  }
  menuFooterSocialEs: menuItems(where: { location: FOOTER_SOCIAL___ES }, first: 100) {
    ${fragmentMenu}
  }
  menuFooterSecondaryEn: menuItems(where: { location: FOOTER_SECONDARY }, first: 100) {
    ${fragmentMenu}
  }
  menuFooterSecondaryEs: menuItems(where: { location: FOOTER_SECONDARY___ES }, first: 100) {
    ${fragmentMenu}
  }
`;
//     fragment GlobalOptions on RootQuery {
//       themeSettings: acfOptionsThemeSettings {
//         acfGlobalOptions {
//           fieldGroupName
//           alertEnabled
//           alertLabel
//           alertLink
//           alertText
//           footerCopyrightOrg
//           formId
//           footerSocialText
//         }
//       }
//     }

//languages is an array of different languages in use on the site
//TODO: Add languages to diffeent query ?
export const queryGlobals = /* GraphQL */ `

  fragment Partners on RootQuery {
    partners(first: 500) {
      nodes{
        title
        postPartners {
          partnerType
          url
        }
      }
    }

    ${fragmentPartnersLookup}

  }

  fragment Multilingual on RootQuery {
    ${fragmentLanguages}
    ${globalThemeSettingsByLanguage}
  }

  fragment Menus on RootQuery {
    ${menus}
  }

  fragment SEO on RootQuery {
    ${seoGlobal}
  }


  fragment Redirects on RootQuery {
    allRedirects: ${redirectsRedirection}
  }

  query AllGlobals {
    ...Redirects
    ...Partners
    ...SEO
    ...Menus
    ...Multilingual
    #...GlobalOptions
  }
`;
