import sectionOptions from './sectionOptions';

export function flexButton(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_Button {
      color
      size
      text
      url {
        target
        title
        url
      }
      ${sectionOptions}
    }
  `;

  return fragment;
}
