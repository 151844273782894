export default function handleShare(target, url = null, text = null) {
  const shareUrl = url || location.origin + location.pathname;
  let prefix = '';
  let shareTweet = '';
  if (target === 'facebook') {
    prefix = 'https://www.facebook.com/sharer/sharer.php?u=';
  } else if (target === 'twitter') {
    prefix = 'https://twitter.com/intent/tweet?url=';
    if (text) {
      shareTweet = `&text=${encodeURIComponent(text)}`;
    } else {
      shareTweet =
        '&text=WATCH NOW: Our vision for America rejects xenophobia, anti-Blackness, and Islamophobia. Watch the video and join us';
    }
  }
  const shareLink = prefix + shareUrl + shareTweet;

  window.open(shareLink, '_blank');
  // `https://www.facebook.com/sharer/sharer.php?u=${
  //   props.shareBarOptions.shareUrl
  //     ? encodeURIComponent(props.shareBarOptions.shareUrl)
  //     : encodeURIComponent(currentURL)
  // }`;

  // `https://twitter.com/intent/tweet?${
  //   props.shareBarOptions.shareTweet
  //     ? `text=${encodeURIComponent(props.shareBarOptions.shareTweet)}`
  //     : ''
  // }&url=${
  //   props.shareBarOptions.shareUrl
  //     ? encodeURIComponent(props.shareBarOptions.shareUrl)
  //     : encodeURIComponent(currentURL)
  // }`;
}
