/*
  Flex section News
  Pulls post type News posts (possibly filtered by category if selected)
*/

import cx from 'classnames';
import { parseISO, format } from 'date-fns';
import _chunk from 'lodash/chunk';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import styles from './FlexNews.module.scss';

const FlexNews = (props) => {
  const [blocksDisplayed, setBlocksDisplayed] = useState(0);
  // posts by the selected category

  // TODO: add logic for default posts if no category selected
  const newsPostsNodes =
    props.newsCategoriesFilter?.[0].newsPost.nodes;

  if (!newsPostsNodes) {
    // no posts? nothing to render
    return null;
  }

  const blockSize = props.rows * 3;
  const blockCount = Math.ceil(newsPostsNodes.length / blockSize);
  const postBlocks = _chunk(newsPostsNodes, blockSize);

  // to format dates
  // const dateISO = parseISO(date);

  // console.log('newsPostsNodes', newsPostsNodes);
  // console.log('props', props);

  function handleLoadMore() {
    setBlocksDisplayed(blocksDisplayed + 1);
  }

  return (
    <div className={cx([styles.outer, props.customClasses])}>
      <div className="container">
        {/*Optional Section Title*/}
        {props.title && (
          <h2 className={styles['sectionHeading']}>{props.title}</h2>
        )}

        <div className="news-posts">
          {/* the news post tiles */}
          <div className="row">
            {postBlocks.map((posts, i) => {
              if (i > blocksDisplayed) {
                return null;
              }

              return posts.map((newsPostNode, j) => {
                let postDateFormatted = format(
                  parseISO(newsPostNode.date),
                  'MMMM d, y',
                );

                // link is either an extenral one (if set) or internal to news post flex page
                let link =
                  newsPostNode.post_type_news.externalUrl ||
                  newsPostNode.uri;

                return (
                  <div className="col-12 col-md-4" key={j}>
                    <Link href={link} className={styles['news-post']}>
                      <a className={styles['news-post-link']}>
                        {/* featured image, if present */}
                        {newsPostNode.featuredImage?.node
                          ?.sourceUrl && (
                          <div className="image">
                            <Image
                              src={
                                newsPostNode.featuredImage.node
                                  .sourceUrl
                              }
                              width={
                                newsPostNode.featuredImage.node
                                  .mediaDetails.width
                              }
                              height={
                                newsPostNode.featuredImage.node
                                  .mediaDetails.height
                              }
                            />
                          </div>
                        )}

                        <div className={styles['news-post-text']}>
                          <time
                            dateTime={postDateFormatted}
                            className="date text-center"
                          >
                            {postDateFormatted}
                          </time>

                          <h4>{newsPostNode.title}</h4>

                          <img
                            className={styles.arrow}
                            src="/arrow-red.svg"
                          />
                        </div>
                      </a>
                    </Link>
                  </div>
                );
              });
            })}

            {blocksDisplayed < blockCount - 1 && (
              <div className="row justify-content-center">
                <div className="col-3">
                  <button
                    className="btn btn-red btn-sm"
                    onClick={handleLoadMore}
                  >
                    <span>Load More</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlexNews;
