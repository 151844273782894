{
  /* Flex section News . Pulls post type News posts (possibly filtered by category if selected)

*/
}

import cx from 'classnames';
import Link from 'next/link';
import styles from './FlexNews.module.scss';

const TileInterior = ({ tile }) => (
  <div className={styles['news-post-text']}>
    {tile.headingDate && (
      <div className="date text-center">{tile.headingDate}</div>
    )}

    <h4>{tile.text}</h4>

    <img className={styles.arrow} src="/arrow-red.svg" />
  </div>
);

const FlexNews = (props) => {
  //const postsToRender = props.rows * 3;

  if (!props.tiles) {
    // no posts? nothing to render
    return null;
  }

  // to format dates
  // const dateISO = parseISO(date);

  // console.log('newsPostsNodes', newsPostsNodes);
  // console.log('props', props);

  return (
    <div className={cx([styles.outer, props.customClasses])}>
      <div className="container">
        {/*Optional Section Title*/}
        {/* {props.title && (
          <h2 className={styles['sectionHeading']}>{props.title}</h2>
        )} */}

        <div className="news-posts">
          {/* the news post tiles */}
          <div className="row">
            {props.tiles.map((tile, index) => {
              return (
                <div className="col-12 col-md-4" key={index}>
                  {(tile.link?.url && (
                    <Link
                      href={tile.link?.url}
                      className={styles['news-post']}
                    >
                      <a className={styles['news-post-link']}>
                        <TileInterior tile={tile} />
                      </a>
                    </Link>
                  )) || (
                    <div className={styles['news-post-link']}>
                      <TileInterior tile={tile} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlexNews;
