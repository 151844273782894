import cx from 'classnames';
import slugify from 'slugify';
import FlexBlockquote from './FlexBlockquote';
import FlexBreadcrumbBar from './FlexBreadcrumbBar';
import FlexButton from './FlexButton';
import FlexCallout from './FlexCallout';
import FlexEvents from './FlexEvents';
import FlexFeaturedContent from './FlexFeaturedContent';
import FlexFormContent from './FlexFormContent';
import FlexHero from './FlexHero';
import FlexImageVideo from './FlexImageVideo';
import FlexImageVideoWithContent from './FlexImageVideoWithContent';
import FlexLanding from './FlexLanding';
import FlexNews from './FlexNews';
import FlexPartners from './FlexPartners';
import FlexPartnersLookup from './FlexPartnersLookup';
import FlexTiles from './FlexTiles';
import FlexWysiwyg from './FlexWysiwyg';

const Flex = ({ sections }) => {
  const renderedSections = [];

  for (let i = 0; i < sections.length; i++) {
    let component, componentName;
    let paddingBottom = true;
    let paddingTop = true;
    const section = sections[i];
    const nextSection = sections[i + 1];

    // collapse padding if two sections in a row have the same color
    if (
      nextSection &&
      nextSection.backgroundColor === section.backgroundColor
    ) {
      paddingBottom = false;
    }

    // for known dark bg's, we'll add white text
    let backgroundDark = false;
    const darkArr = [
      'primary',
      'black',
      'dark',
      'blue_tile',
      'red_tile',
    ];
    if (darkArr.includes(section.backgroundColor)) {
      backgroundDark = true;
      section.backgroundDark = true; // propagate to section props
    }

    // Pass the flex index to the section so we can prioritize
    // image loading for first sections, etc.
    section.index === i;

    // look for any passed in classes, pass along to child
    section.customClasses = section.sectionClasses?.split(' ');

    // uncomment if you need to debug graphql, but don't commit
    // console.log(section);
    if (section?.fieldGroupName?.includes('Breadcrumbbar')) {
      componentName = 'breadcrumbBar';
      component = <FlexBreadcrumbBar {...section} />;
      paddingTop = false;
      paddingBottom = false;
    } else if (section.fieldGroupName?.includes('Button')) {
      componentName = 'button';
      component = <FlexButton {...section} />;
    } else if (section.fieldGroupName?.includes('Callout')) {
      componentName = 'callout';
      component = <FlexCallout {...section} />;
      paddingTop = false;
    } else if (section.fieldGroupName?.includes('Events')) {
      componentName = 'events';
      component = <FlexEvents {...section} />;
    } else if (section.fieldGroupName?.includes('FeaturedContent')) {
      componentName = 'featuredContent';
      component = <FlexFeaturedContent {...section} />;
    } else if (section.fieldGroupName?.includes('Formwithcontent')) {
      componentName = 'form';
      component = <FlexFormContent {...section} />;
      paddingBottom = false;
    } else if (
      section.fieldGroupName?.includes('FullWidthImageVideo')
    ) {
      componentName = 'fullWidthImageVideo';
      component = <FlexImageVideo {...section} />;
      paddingTop = false;
      paddingBottom = false;
    } else if (section.fieldGroupName?.includes('Hero')) {
      componentName = 'hero';
      component = <FlexHero {...section} />;
      paddingTop = false;
      paddingBottom = false;
    } else if (
      section.fieldGroupName?.includes('ImageVideoWithContent')
    ) {
      componentName = 'imagevideoWithContent';
      component = <FlexImageVideoWithContent {...section} />;
      paddingTop = !section.fullWidth;
      paddingBottom = !section.fullWidth;
    } else if (section.fieldGroupName?.includes('News')) {
      componentName = 'news';
      component = <FlexNews {...section} />;
      paddingTop = false;
      paddingBottom = false;
    } else if (section.fieldGroupName?.includes('PartnersList')) {
      componentName = 'partnerslist';
      component = <FlexPartners {...section} />;
    } else if (section.fieldGroupName?.includes('PartnersLookup')) {
      componentName = 'partnerslookup';
      component = <FlexPartnersLookup {...section} />;
    } else if (section.fieldGroupName?.includes('Tiles')) {
      componentName = 'tiles';
      component = <FlexTiles {...section} />;
      paddingTop = false;
      paddingBottom = false;
    } else if (section.fieldGroupName?.includes('WysiwygContent')) {
      componentName = 'wysiwyg';
      component = <FlexWysiwyg {...section} />;
    } else if (section.fieldGroupName?.includes('Landing')) {
      componentName = 'landing';
      component = <FlexLanding {...section} />;
    }

    const classNames = cx({
      [`flex-${componentName}`]: true,
      ['text-white']: backgroundDark,
      'section-padded': paddingTop || paddingBottom,
      ['pt-0']: !paddingTop,
      ['pb-0']: !paddingBottom,
      [`bg-${section.backgroundColor}`]: true,
    });

    // add slug ID if passed in
    let slug = '';
    if (section.sectionSlug) {
      slug = slugify(section.sectionSlug, { lower: true });
    }

    renderedSections.push(
      <section key={i} className={classNames} id={slug}>
        {component}
      </section>,
    );
  }

  return <>{renderedSections}</>;
};

export default Flex;
