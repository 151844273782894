import cx from 'classnames';
import Button from 'components/Button';
import LiteYoutubeEmbed from 'components/LiteYouTube';
import handleShare from 'lib/handleShare';
import Image from 'next/image';
import Link from 'next/link';
// import { Plyr } from 'plyr-react';
import { useState } from 'react';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import styles from './FlexImageVideoWithContent.module.scss';
// import 'plyr-react/dist/plyr.css';

const FlexImageVideoWithContent = (props) => {
  const ids = {
    english: props?.video?.split('v=')[1] || null,
    spanish: props?.videoSpanish?.split('v=')[1] || null,
  };

  const [language, updateLanguage] = useState('english');

  function toggleLanguage(language) {
    updateLanguage(language);
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className={cx(
              'col-12',
              props.layoutOptions.imageVideoWidth === 'third'
                ? 'col-md-4'
                : 'col-md-6',
              props.layoutOptions.mobileContentPlacement === 'bottom'
                ? 'order-1'
                : 'order-2',
              props.layoutOptions.contentOrientation === 'left'
                ? 'order-md-1'
                : 'order-md-2',
            )}
          >
            {props.imageOrVideo === 'image' &&
              props.image?.sourceUrl &&
              props.imageLink?.url && (
                <Link href={props.imageLink?.url}>
                  <a>
                    <Image
                      src={props.image.sourceUrl}
                      width={props.image.mediaDetails.width}
                      height={props.image.mediaDetails.height}
                      alt="Image"
                    />
                  </a>
                </Link>
              )}
            {props.imageOrVideo === 'image' &&
              props.image?.sourceUrl &&
              !props.imageLink?.url && (
                <Image
                  src={props.image.sourceUrl}
                  width={props.image.mediaDetails.width}
                  height={props.image.mediaDetails.height}
                  alt="Image"
                />
              )}
            {props.videoBar && (
              <div className={styles.shareBar}>
                {props.languageBarOptions?.shareText &&
                  props.languageBarOptions.shareText}
                <button
                  role="button"
                  className={cx(styles.languageBtn, {
                    [styles.langSelected]: language === 'english',
                  })}
                  onClick={() => toggleLanguage('english')}
                >
                  {props.languageBarOptions?.englishText}
                </button>
                <button
                  role="button"
                  className={cx(styles.languageBtn, {
                    [styles.langSelected]: language === 'spanish',
                  })}
                  onClick={() => toggleLanguage('spanish')}
                >
                  {props.languageBarOptions?.spanishText}
                </button>
              </div>
            )}
            {ids.english && (
              <div className={styles.videoContainer}>
                {/* <Plyr
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: ids[language],
                        provider: 'youtube',
                      },
                    ],
                  }}
                /> */}
                {/* <YouTube
                  className={styles.youtube}
                  videoId={ids[language]}
                  opts={{
                    height: '100%',
                    width: '100%'
                  }} */}
                <LiteYoutubeEmbed id={ids[language]} />
              </div>
            )}
            {props.shareBar && (
              <div className={styles.shareBar}>
                {props.shareBarOptions?.shareText &&
                  props.shareBarOptions.shareText}
                <button
                  className={styles.socialIcon}
                  role="button"
                  onClick={() =>
                    handleShare(
                      'facebook',
                      props.shareBarOptions.shareUrl,
                    )
                  }
                >
                  <FaFacebookF color="white" />
                </button>
                <button
                  className={cx(styles.socialIcon, styles.last)}
                  role="button"
                  onClick={() =>
                    handleShare(
                      'twitter',
                      props.shareBarOptions.shareUrl,
                      props.shareBarOptions.shareTweet,
                    )
                  }
                >
                  <FaTwitter color="white" />
                </button>
              </div>
            )}
          </div>
          <div
            className={cx(
              'col-12',
              props.layoutOptions.imageVideoWidth === 'third'
                ? 'col-md-8'
                : 'col-md-6',
              props.layoutOptions.mobileContentPlacement === 'bottom'
                ? 'order-2'
                : 'order-1',
              props.layoutOptions.contentOrientation === 'left'
                ? 'order-md-2'
                : 'order-md-1',
            )}
          >
            <div
              className={cx(styles.imageTextContainer, {
                [styles.contentPadded]:
                  props.layoutOptions.mobileContentPlacement ===
                    'bottom' && props.headingText,
              })}
            >
              {props.headingText && (
                <div
                  className={cx(
                    styles.headingText,
                    styles[props.headingOptions.headingColor],
                    {
                      [styles.stampContainer]:
                        props.headingOptions.headingStamp,
                    },
                  )}
                >
                  <div
                    className={cx({
                      [styles.stamp]:
                        props.headingOptions.headingStamp,
                    })}
                  >
                    {props.headingText}
                  </div>
                </div>
              )}
              {props.subheadingText && (
                <div
                  className={cx(
                    styles.subheadingText,
                    styles[props.headingOptions.headingColor],
                    {
                      [styles.subheadingPadding]: !props.headingText,
                    },
                  )}
                >
                  {props.subheadingText}
                </div>
              )}
              {props.bodyText && (
                <div
                  className={styles.bodyText}
                  dangerouslySetInnerHTML={{
                    __html: props.bodyText,
                  }}
                />
              )}
              {props.buttonUrl?.url && (
                <Button
                  color={props.buttonColor}
                  link={props.buttonUrl.url}
                  size={props.buttonSize}
                  text={props.buttonText}
                  target={props.buttonUrl?.target}
                  style={{ marginTop: '20px' }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlexImageVideoWithContent;
