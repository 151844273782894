import sectionOptions from './sectionOptions';

export function flexFormwithcontent(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_Formwithcontent {
      formButtonText
      formHeading
      everyactionUrl
      inlineThankYouButton {
        color
        size
        text
        url {
          url
          target
          title
        }
      }
      inlineThankYouContent
      inlineThankYouTitle
      sectionContent
      sectionHeading
      ${sectionOptions}
    }
  `;

  return fragment;
}
