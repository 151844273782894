import cx from 'classnames';
import GlobalsContext from 'contexts/GlobalsContext';
import formatMenu from 'lib/formatMenu';
import handleShare from 'lib/handleShare';
import _get from 'lodash/get';
import Link from 'next/link';
import { useContext } from 'react';
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaYoutube,
} from 'react-icons/fa';
import styles from './Footer.module.scss';
import Partners from './Partners';

export default function Footer() {
  const globals = useContext(GlobalsContext);

  let globalsByLanguage = _get(
    globals,
    'acfThemeSettingsByLanguage.acfOptionsThemeSettingsByLanguage',
  );

  let language = _get(globals, 'language', 'en');

  let footerGroup = {
    copyright: '',
  };

  if (globalsByLanguage && globalsByLanguage[language]) {
    footerGroup = globalsByLanguage[language].footergroup;
  }

  let socialNavArr =
    language == 'es'
      ? globals?.menuFooterSocialEs?.nodes
      : globals?.menuFooterSocialEn?.nodes;

  const socialNav = Array.isArray(socialNavArr)
    ? formatMenu(socialNavArr)
    : [];

  let secondaryNavArr =
    language == 'es'
      ? globals?.menuFooterSecondaryEs?.nodes
      : globals?.menuFooterSecondaryEn?.nodes;

  const secondaryNav = Array.isArray(secondaryNavArr)
    ? formatMenu(secondaryNavArr)
    : [];

  // console.log('footerGroup: ', footerGroup);

  return (
    <>
      <footer className={cx('text-white', styles.footer)}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-8 col-xl-9 order-2 order-md-1">
              <div className="mt-3 mt-md-0">
                &copy; Copyright {new Date().getFullYear()}
                {footerGroup.copyright}{' '}
                {secondaryNav.length > 0 && (
                  <ul
                    className={cx([
                      styles.secondaryNav,
                      'list-inline mb-0 d-block mt-2',
                    ])}
                  >
                    {secondaryNav.map((item, i) => (
                      <li className="list-inline-item" key={i}>
                        <Link
                          href={item.path}
                          target={item.target}
                          prefetch={false}
                        >
                          <a>{item.label}</a>
                        </Link>
                        {/* <span className={styles.divider}>|</span> */}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            {language == 'en' && (
              <div className="col-12 col-md-5 col-lg-4 col-xl-3 order-1 order-md-2">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <button
                      className={styles.socialButton}
                      role="button"
                      onClick={(e) => handleShare('facebook')}
                    >
                      <FaFacebookSquare />
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <button
                      className={styles.socialButton}
                      role="button"
                      onClick={(e) => handleShare('twitter')}
                    >
                      <FaTwitter />
                    </button>
                  </li>
                  {/* <li className="list-inline-item">
                  <Link href="https://www.instagram.com">
                    <a aria-label="Instagram">
                      <FaInstagram />
                    </a>
                  </Link>
                </li> */}
                  <li className="list-inline-item">
                    <Link href="https://www.youtube.com/watch?v=VZNDbFhBBus">
                      <a aria-label="YouTube">
                        <FaYoutube />
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </footer>
    </>
  );
}
