import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import cx from 'classnames';
import Button from 'components/Button';
import GlobalsContext from 'contexts/GlobalsContext';
import dayjs from 'dayjs';
import fetcher from 'lib/gsheet';
import _get from 'lodash/get';
import { useState, useCallback } from 'react';
import { useContext } from 'react';
import useSWR from 'swr';
import styles from './FlexEvents.module.scss';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const EventDropdown = (props) => (
  <div className="row justify-content-center">
    <div className="col col-lg-10">
      <div className={styles.dropdownContainer}>
        <div className={cx('fired-up-embed', styles.embed)}>
          <div className={cx('field-state', styles.fieldState)}>
            <label htmlFor="state" className="control-label">
              Show events in:
            </label>
            <select
              className={cx('form-control', styles.select)}
              name="state"
              id="state"
              aria-label="State"
              label="State"
              value={props.currentState}
              onChange={(event) =>
                props.updateState(event.target.value)
              }
            >
              <option value="US">Nationwide</option>
              <option value="AR">Arkansas</option>
              <option value="AZ">Arizona</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DC">District of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="ME">Maine</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MO">Missouri</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="VT">Vermont</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const EventIntro = ({ title, blurb }) => (
  <div className="row justify-content-center">
    <div className="col-12 col-sm-11 col-md-9 col-lg-8 col-xl-7 col-xxl-6">
      <div className={cx(styles.eventIntro, 'wysiwyg-content')}>
        {title && <h3>{title}</h3>}
        {blurb && <p>{blurb}</p>}
      </div>
    </div>
  </div>
);

const EventList = ({ events, currentState }) => {
  /* eslint-disable */
  const filteredEvents =
    currentState == 'US'
      ? events.filter((item) => {
          try {
            const endDate = item[5] || item[3];
            return dayjs(endDate).isAfter(dayjs().subtract(1, 'day'));
          } catch {
            return false;
          }
        })
      : events.filter((item) => {
          try {
            const endDate = item[5] || item[3];
            return (
              item[8] == currentState &&
              dayjs(endDate).isAfter(dayjs().subtract(1, 'day'))
            );
          } catch {
            return false;
          }
        });
  /* eslint-enable */
  const sortedEvents = filteredEvents.sort((a, b) => {
    try {
      const dtOne = dayjs(`${a[3].trim()}T${a[4].trim()}:00.000Z`);
      const dtTwo = dayjs(`${b[3].trim()}T${b[4].trim()}:00.000Z`);
      if (dtOne.isBefore(dtTwo)) {
        return -1;
      } else if (dtOne.isAfter(dtTwo)) {
        return 1;
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  });
  return (
    <div className="row justify-content-center">
      <div className="col col-lg-10">
        <div className={cx(styles.eventList, 'wysiwyg-content')}>
          {sortedEvents.map((item) => (
            <EventSingle props={item} key={item[0]} />
          ))}
        </div>
      </div>
    </div>
  );
};

const EventSingle = ({ props, info }) => (
  <div
    className={cx(styles.eventSingle, {
      [styles.infoSingle]: info,
    })}
  >
    {props[0] && <div className={styles.eventTitle}>{props[0]}</div>}
    {props[1] && (
      <div className={styles.eventHost}>Hosted by: {props[1]}</div>
    )}
    {props[2] && <div className={styles.eventDate}>{props[2]}</div>}
    {props[9] && (
      <div className={styles.eventLocation}>{props[9]}</div>
    )}
    {props[6] && (
      <div className={styles.eventDescription}>{props[6]}</div>
    )}
    {props[7] && (
      <a
        href={props[7]}
        target="_blank"
        rel="noreferrer"
        className={styles.eventLink}
      >
        RSVP Here
      </a>
    )}
  </div>
);

const FlexEvents = (props) => {
  const globals = useContext(GlobalsContext);
  const language = _get(globals, 'language', 'en');

  const { data } = useSWR(['/api/getsheet', language], fetcher);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBbngoXpn8AFJixpcRUjqaZxPTURY6ORgc',
  });

  const stateDetails = {
    US: { center: { lat: 39.833333, lng: -98.583333 }, zoom: 4 },
    CA: { center: { lat: 36.778261, lng: -119.4179324 }, zoom: 5.4 },
    CO: { center: { lat: 39.5500507, lng: -105.7820674 }, zoom: 6 },
    OR: { center: { lat: 43.8041334, lng: -120.5542012 }, zoom: 6.4 },
    MN: { center: { lat: 46.729553, lng: -94.6858998 }, zoom: 5.9 },
    WI: { center: { lat: 43.7844397, lng: -88.7878678 }, zoom: 6.1 },
    DC: { center: { lat: 38.9071923, lng: -77.0368707 }, zoom: 11 },
    MA: { center: { lat: 42.4072107, lng: -71.3824374 }, zoom: 8 },
    NY: { center: { lat: 42.6525793, lng: -73.7562317 }, zoom: 6.4 },
    AR: { center: { lat: 35.20105, lng: -91.8318334 }, zoom: 6.7 },
    UT: { center: { lat: 39.3209801, lng: -111.0937311 }, zoom: 6.3 },
    CT: { center: { lat: 41.6032207, lng: -73.087749 }, zoom: 8 },
    PA: { center: { lat: 41.2033216, lng: -77.1945247 }, zoom: 6.9 },
    WA: { center: { lat: 47.7510741, lng: -120.7401385 }, zoom: 6.5 },
    VT: { center: { lat: 44.5588028, lng: -72.5778415 }, zoom: 6.7 },
    NM: { center: { lat: 34.5199402, lng: -105.8700901 }, zoom: 6.4 },
    ME: { center: { lat: 45.253783, lng: -69.4454689 }, zoom: 6.5 },
    FL: { center: { lat: 27.6648274, lng: -81.5157535 }, zoom: 6.2 },
    AZ: { center: { lat: 34.0489281, lng: -111.0937311 }, zoom: 6.2 },
    GA: { center: { lat: 32.1656221, lng: -82.9000751 }, zoom: 6.2 },
    IA: { center: { lat: 41.8780025, lng: -93.097702 }, zoom: 6.8 },
    IL: { center: { lat: 40.6331249, lng: -89.3985283 }, zoom: 6.2 },
    IN: { center: { lat: 40.2671941, lng: -86.1349019 }, zoom: 6.7 },
    MO: { center: { lat: 37.9642529, lng: -91.8318334 }, zoom: 6.3 },
    NC: { center: { lat: 35.7595731, lng: -79.0192997 }, zoom: 7 },
    NH: { center: { lat: 43.1938516, lng: -71.5723953 }, zoom: 6.7 },
    NJ: { center: { lat: 40.0583238, lng: -74.4056612 }, zoom: 7.4 },
    TX: { center: { lat: 31.9685988, lng: -99.9018131 }, zoom: 5.4 },
    WV: { center: { lat: 38.5976262, lng: -80.4549026 }, zoom: 6.7 },
    MI: { center: { lat: 44.3148443, lng: -85.6023643 }, zoom: 5.9 },
  };

  const [map, setMap] = useState(null);
  const [selectState, setSelectState] = useState('US');
  const [showInfo, setShowInfo] = useState(false);

  // const onLoad = useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map);
  // }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const toggleInfo = (id) => {
    if (!id || (showInfo && showInfo[0] == id)) {
      setShowInfo(false);
    } else {
      setShowInfo(data.find((item) => item[0] == id));
    }
  };

  return (
    <div className="container">
      <EventIntro
        title={props.eventsHeading}
        blurb={props.eventsIntroBlurb}
      />
      {!props.hideFilters && (
        <EventDropdown
          currentState={selectState}
          updateState={setSelectState}
        />
      )}
      {isLoaded && !props.hideMap ? (
        <div className="row justify-content-center">
          <div className="col col-lg-10">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={stateDetails[selectState].center}
              zoom={stateDetails[selectState].zoom}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
              }}
              // onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={() => toggleInfo(false)}
            >
              {data &&
                data.map((item, i) => (
                  <Marker
                    key={item[0]}
                    position={{
                      lat: parseFloat(item[10]),
                      lng: parseFloat(item[11]),
                    }}
                    onClick={() => toggleInfo(item[0])}
                  />
                ))}
              {showInfo && (
                <InfoWindow
                  position={{
                    lat: parseFloat(showInfo[10]),
                    lng: parseFloat(showInfo[11]),
                  }}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -30),
                  }}
                  onCloseClick={() => toggleInfo(false)}
                >
                  <EventSingle props={showInfo} info={true} />
                </InfoWindow>
              )}
            </GoogleMap>
          </div>
        </div>
      ) : (
        <></>
      )}
      {data && <EventList events={data} currentState={selectState} />}
      {data && selectState !== 'US' && (
        <div className="row justify-content-center mt-3">
          <div className="col-10 text-center">
            <Button
              onClick={() => setSelectState('US')}
              color="red"
              size="md"
              text="Show All Events"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FlexEvents;
