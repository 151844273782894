import cx from 'classnames';
import PostBody from 'components/post/PostBody';
import styles from './FlexWysiwyg.module.scss';

const FlexWysiwyg = (props) => {
  return (
    <div className={cx([styles.outer, props.customClasses])}>
      <div className="container">
        <div className="row justify-content-center">
          <div
            className={cx(
              'col-12',
              props.contentWidth === 'wide'
                ? 'col-sm-12 col-md-12 col-lg-12 col-xl-11 col-xxl-10'
                : 'col-sm-11 col-md-9 col-lg-8 col-xl-7 col-xxl-6',
            )}
          >
            {props.sectionHeading && (
              <h2 className={styles.sectionHeading}>
                {props.sectionHeading}
              </h2>
            )}
            <div className="wysiwyg-content">
              {props.wysiwygContent && (
                <PostBody content={props.wysiwygContent} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlexWysiwyg;
