import sectionOptions from './sectionOptions';

export function flexNews(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_News {
      rows
      title
      newsCategoriesFilter {
        name
        newsPost(first: 1000) {
          nodes {
            date
            link
            title
            slug
            uri
            post_type_news {
              externalUrl
            }
            featuredImage {
              node {
                sourceUrl(size: MEDIUM)
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
        }
      }
      ${sectionOptions}
    }
  `;

  return fragment;
}
