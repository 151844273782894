import { flexBreadcrumbbar } from './fragments/flexBreadcrumbbar';
import { flexButton } from './fragments/flexButton';
import { flexCallout } from './fragments/flexCallout';
import { flexEvents } from './fragments/flexEvents';
import { flexFeaturedContent } from './fragments/flexFeaturedContent';
import { flexFormwithcontent } from './fragments/flexFormwithcontent';
import { flexFullWidthImageVideo } from './fragments/flexFullWidthImageVideo';
import { flexImageVideoWithContent } from './fragments/flexImageVideoWithContent';
import { flexLanding } from './fragments/flexLanding';
import { flexNews } from './fragments/flexNews';
import { flexPartners } from './fragments/flexPartners';
import { flexPartnersLookup } from './fragments/flexPartnersLookup';
import { flexTiles } from './fragments/flexTiles';
import { flexWysiwygContent } from './fragments/flexWysiwygContent';
import postTranslations from './fragments/postTranslations';
import SEO from './fragments/SEO.js';

export const queryContent = /* GraphQL */ `
  query GetContent($slug: ID!, $preview: Boolean) {
    contentNode(id: $slug, idType: URI, asPreview: $preview) {
      __typename
      id
      databaseId
      isPreview
      link
      slug
      uri
      template {
        ... on Template_Flex {
          acfFlex {
            fieldGroupName
            flexContent {
              ${flexBreadcrumbbar()}
              ${flexButton()}
              ${flexCallout()}
              ${flexEvents()}
              ${flexFeaturedContent()}
              ${flexFormwithcontent()}
              ${flexFullWidthImageVideo()}
              ${flexImageVideoWithContent()}
              ${flexLanding()}
              ${flexNews()}
              ${flexTiles()}
              ${flexPartners()}
              ${flexPartnersLookup()}
              ${flexWysiwygContent()}
            }
          }
        }
      }
      ... on NodeWithTitle {
        title
      }
      ... on NodeWithFeaturedImage {
        featuredImage {
          node {
            caption
            sourceUrl
            mediaDetails {
              height
              width
            }
          }
        }
      }
      ... on NodeWithTemplate {
        template {
          __typename
          templateName
        }
      }
      ... on NodeWithAuthor {
        authorId
        author {
          node {
            id
            name
            nicename
            lastName
          }
        }
      }
      ... on Page {
        isFrontPage
        content
        ${postTranslations}
        variant {
          c4Page
        }
        ${SEO}
      }
      ... on Post {
        content
        ${postTranslations}
        ${SEO}
      }
      ... on News {
        content
        ${postTranslations}
        variant {
          c4Page
        }
        post_type_news {
          externalUrl
        }
        ${SEO}
      }
    }
  }
`;
