import cx from 'classnames';
import { EmailShare, Arrow } from 'components/svg';
import GlobalsContext from 'contexts/GlobalsContext';
import handleShare from 'lib/handleShare';
import _get from 'lodash/get';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState, Fragment } from 'react';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { FaFacebook } from 'react-icons/fa';
import styles from './FlexBreadcrumbBar.module.scss';

//Breadcrumb adapted from https://github.com/NiklasMencke/nextjs-breadcrumbs/
const convertBreadcrumb = (string) => {
  return string
    .replace(/-/g, ' ')
    .replace(/oe/g, 'ö')
    .replace(/ae/g, 'ä')
    .replace(/ue/g, 'ü')
    .toUpperCase();
};

const FlexBreadCrumbBar = (props) => {
  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [isOpen, toggleOpen] = useState(false);
  const globals = useContext(GlobalsContext);

  let globalsByLanguage = _get(
    globals,
    'acfThemeSettingsByLanguage.acfOptionsThemeSettingsByLanguage',
  );

  let language = _get(globals, 'language', 'en');

  let headerGroup = {
    breadcrumbTopLevelText: 'Home',
  };

  if (globalsByLanguage && globalsByLanguage[language]) {
    headerGroup = globalsByLanguage[language].headergroup;
  }

  useEffect(() => {
    if (router) {
      const linkPath = router.asPath.split('/');
      linkPath.shift();

      const pathArray = linkPath.map((path, i) => {
        return {
          breadcrumb: path,
          href: '/' + linkPath.slice(0, i + 1).join('/'),
        };
      });

      setBreadcrumbs(pathArray);
    }
  }, [router]);

  useEffect(() => {
    window.addEventListener('resize', () => toggleOpen(false));
  }, []);

  function handleToggle() {
    toggleOpen(!isOpen);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <nav
          className={cx(
            'navbar navbar-expand-lg',
            styles.breadcrumbNav,
            { [styles.addMargin]: isOpen },
          )}
          aria-label="breadcrumbs"
        >
          <div className="col-10 col-lg-8">
            <ul className={cx('nav', styles.breadcrumbContainer)}>
              <li className="nav-item">
                <a href="/">
                  <span className="text-offset">
                    {headerGroup.breadcrumbTopLevelText}
                  </span>
                </a>
              </li>
              {breadcrumbs &&
                breadcrumbs.map((breadcrumb, i) => {
                  if (breadcrumb.href === router.asPath) {
                    return (
                      <Fragment key={i}>
                        <li className={styles.arrow}>
                          <Arrow width="100%" height="100%" />
                        </li>
                        <li className="nav-item">
                          <div aria-current="page">
                            <span className="text-offset">
                              {convertBreadcrumb(
                                breadcrumb.breadcrumb,
                              )}
                            </span>
                          </div>
                        </li>
                      </Fragment>
                    );
                  } else {
                    return (
                      <li className="nav-item" key={breadcrumb.href}>
                        <Link href={breadcrumb.href}>
                          <a className="nav-link">
                            <span className="text-offset">
                              {convertBreadcrumb(
                                breadcrumb.breadcrumb,
                              )}
                            </span>
                          </a>
                        </Link>
                      </li>
                    );
                  }
                })}
            </ul>
          </div>
          <div className="col-2 col-lg-4">
            <div className={styles.shareNav}>
              <div
                role="button"
                className="navbar-toggler border-0"
                aria-controls="navbarShare"
                aria-expanded="false"
                aria-label="Toggle share"
                onClick={handleToggle}
              >
                SHARE
              </div>
              <div
                className={cx('collapse', styles.shareContainer, {
                  show: isOpen,
                  ['navbar-collapse']: !isOpen,
                  [styles.shareOpen]: isOpen,
                  'justify-content-end': !isOpen,
                  'justify-content-center': isOpen,
                })}
                id="navbarShare"
              >
                <ul className="nav">
                  <li className="nav-item">
                    <span
                      className={cx('nav-link', styles['share-link'])}
                      role="button"
                      onClick={(e) =>
                        handleShare('facebook', props.shareUrl)
                      }
                    >
                      <FaFacebook
                        title="Facebook Share"
                        color="#4267B2"
                        size="2.4em"
                      />
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={cx('nav-link', styles['share-link'])}
                      role="button"
                      onClick={(e) =>
                        handleShare(
                          'twitter',
                          props.shareUrl,
                          props.shareTweet,
                        )
                      }
                    >
                      <AiFillTwitterCircle
                        title="Twitter Share"
                        color="#1DA1F2"
                        size="2.9em"
                        style={{ marginTop: '-3px' }}
                      />
                    </span>
                  </li>
                  <li className="nav-item">
                    <a
                      href={`mailto:?${
                        props.shareEmailSubject
                          ? `subject=${encodeURIComponent(
                              props.shareEmailSubject,
                            )}`
                          : ''
                      }${
                        props.shareEmailBody
                          ? `&body=${encodeURIComponent(
                              props.shareEmailBody.replace(
                                /<br\s*\/?>/gm,
                                '\r',
                              ),
                            )}`
                          : ''
                      }`}
                      className={cx('nav-link', styles['share-link'])}
                    >
                      <EmailShare
                        title="Email Share"
                        color="#58A09C"
                        width="2.4em"
                        height="2.4em"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default FlexBreadCrumbBar;
