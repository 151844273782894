import cx from 'classnames';
import Button from 'components/Button';
import styles from './FlexFeaturedContent.module.scss';

const FlexFeaturedContent = (props) => {
  return (
    <div
      className={cx({ container: !props.layoutOptions.fullWidth })}
    >
      <div
        className={cx(styles.textContainer, {
          [styles.mobileMargin]: !props.layoutOptions.fullWidth,
        })}
      >
        <div
          className={cx({ container: props.layoutOptions.fullWidth })}
        >
          <div className="row">
            {props.headingText && (
              <h2
                className={cx(
                  styles.sectionHeading,
                  props.backgroundDark
                    ? ''
                    : styles.sectionHeadingBgLight,
                )}
              >
                {props.headingText}
              </h2>
            )}
          </div>
          <div
            className={cx('row', styles.contentRow, {
              [styles.contentPadded]:
                props.layoutOptions.columns === 'one',
            })}
          >
            <div
              className={cx(
                'col-12',
                props.layoutOptions.columns === 'two'
                  ? 'col-md-6 col-xxl-5'
                  : 'order-2',
              )}
            >
              {props.bodyText && (
                <div
                  className={cx(
                    styles.bodyText,
                    {
                      'd-none d-md-block':
                        props.layoutOptions.hideBodyText,
                    },
                    props.layoutOptions.columns === 'two'
                      ? styles.bodyTextFirst
                      : styles.bodyTextSecond,
                  )}
                  dangerouslySetInnerHTML={{
                    __html: props.bodyText,
                  }}
                />
              )}
            </div>
            <div
              className={cx(
                'col-12',
                props.layoutOptions.columns === 'two'
                  ? 'col-md-6 col-xxl-7'
                  : 'order-1',
              )}
            >
              {props.subheadingText && (
                <div
                  className={cx(
                    styles.subheadingText,
                    props.layoutOptions.columns === 'two'
                      ? styles.subheadingSecond
                      : styles.subheadingFirst,
                  )}
                  dangerouslySetInnerHTML={{
                    __html: props.subheadingText,
                  }}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col text-center pt-5">
              {props.buttonText && (
                <Button
                  text={props.buttonText}
                  size={props.buttonSize}
                  color={props.buttonColor}
                  link={props.buttonUrl.url}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlexFeaturedContent;
