import sectionOptions from './sectionOptions';

export function flexPartnersLookup(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_PartnersLookup {
      sectionHeading
      sectionIntro
      ${sectionOptions}
    }
  `;

  return fragment;
}
