import Button from '../Button';

const FlexButton = (props) => (
  <div className="container">
    <div className="row">
      <div className="col text-center">
        <Button
          color={props.color}
          size={props.size}
          text={props.text}
          link={props.url?.url}
          target={props.url?.target}
        />
      </div>
    </div>
  </div>
);

export default FlexButton;
