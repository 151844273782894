import cx from 'classnames';
import PostBody from 'components/post/PostBody';
import styles from './FlexCallout.module.scss';

const FlexCallout = (props) => {
  return (
    <div className={cx([styles.outer, props.customClasses])}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-xl-11 col-xxl-10">
            <div className={styles.calloutContent}>
              {props.sectionHeading && (
                <h2 className={styles.sectionHeading}>
                  {props.sectionHeading}
                </h2>
              )}
              {props.calloutText && (
                <PostBody content={props.calloutText} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlexCallout;
