import cx from 'classnames';
import GlobalsContext from 'contexts/GlobalsContext';
import { parse } from 'csv-parse/sync';
import Link from 'next/link';
import qs from 'query-string';
import { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import styles from './FlexPartnersLookup.module.scss';

const FlexPartnersLookup = (props) => {
  const [counties, setCounties] = useState([]);
  const [states, setStates] = useState([]);

  const [activeCounty, setActiveCounty] = useState();
  const [activeState, setActiveState] = useState(); // currently selected state (if any)

  const [partners, setPartners] = useState([]);

  const [useTestingData, setUseTestingData] = useState(false);

  // get the csv url from theme settings
  const globalsConsumer = useContext(GlobalsContext);
  const globals =
    globalsConsumer?.acfGlobalThemeSettings?.partnersLookup || {};
  const prodData = globals?.partnersProductionData?.mediaItemUrl;
  const testData = globals?.partnersTestingData?.mediaItemUrl;

  // map any custom classes to our SCSS module style object
  let moduleClassNames = props.customClasses?.map((className) => {
    return styles[className];
  });

  useEffect(() => {
    (async function () {
      let counties = []; // TODO: remove counties after making states the filtering ategory
      let states = [];

      // decide which csv file url use to render, depending if (url has /?testing=true)
      const queries = qs.parse(location.search);
      const isTesting = queries.testing;
      const fileURL = new URL(isTesting ? testData : prodData);

      // got the csv url? get it and parse it
      let csvUrl = `/api/upstream-proxy/?url=${encodeURIComponent(
        fileURL.pathname,
      )}`;
      const response = await fetch(csvUrl);

      let csv = await response.text();
      csv = csv.replace(/^\ufeff/, ''); // *** strip the BOM at first character if present ***
      const csvParseOptions = {
        columns: true,
        delimiter: ',',
        ltrim: true,
        rtrim: true,
      };

      let rows = parse(csv, csvParseOptions);

      // First pass through the rows: poulate the array
      //states for the dropdown filter ["Alabama",.. "New York", ..]
      for (const row of rows) {
        if (!states.includes(row.State)) {
          states.push(row.State);
        }
      }

      states = states.sort();

      // Initialize properties
      setStates(states);
      setPartners(rows);
      setUseTestingData(!!isTesting);
    })();
  }, []);

  // Event handling
  // US State update
  function handleSelectChange({ value }) {
    setActiveState(value);
  }

  // Data filtering based on the active US state.
  // We'll filter based on the state selected in the <Select> below
  const activePartners = partners.filter(
    (row) => row.State === activeState,
  );

  return (
    <section
      id={props.slug}
      className={cx([
        props.classNames,
        moduleClassNames,
        styles[props.flexClass],
      ])}
    >
      <div className={cx(styles.partnersHeader, 'section-padded')}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <h2 className={styles.sectionHeading}>
                {props.sectionHeading}
              </h2>
              <div className={styles.sectionIntro}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.sectionIntro,
                  }}
                ></p>
              </div>

              {useTestingData && (
                <p>
                  <em>
                    <small>Note: Testing mode is active.</small>
                  </em>
                </p>
              )}

              {/** US State Select */}
              <div className="mb-1">
                <Select
                  className={cx('partners-select')}
                  options={states.map((state) => ({
                    value: state,
                    label: state,
                  }))}
                  placeholder="Select state..."
                  onChange={handleSelectChange}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(236, 82, 66, 0.2)',
                      primary: '#ec5242',
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container section-padded bg-purple">
        {activeState && (
          <div className="col-sm-12">
            <h2 className="mt-3">{activeState}</h2>
            {/**
            <p>
              <small className={styles.disclaimer}>
                {activePartners[0].county_disclaimer}
              </small>
            </p>
            */}
          </div>
        )}

        <div className="row mt-3">
          {/** list out the results */}
          {activePartners.map((partner, key) => {
            let email = partner['Contact Info'];
            return (
              <div
                className={cx('col-lg-6', styles.result)}
                key={key}
              >
                <div className={styles.resultInner}>
                  <h3>
                    {' '}
                    <Link
                      href={partner['Link to Organization']}
                      className={styles['news-post']}
                    >
                      <a
                        target="_blank"
                        className={styles['news-post-link']}
                      >
                        {partner['Organization Name']}
                      </a>
                    </Link>
                  </h3>

                  {email && (
                    <p>
                      Email:{' '}
                      <Link href={'mailto:' + email}>
                        <a target="_blank">{email}</a>
                      </Link>
                    </p>
                  )}

                  {/**
                 <p
                  dangerouslySetInnerHTML={{
                    __html: location.location_display.replaceAll(
                      '\n',
                      '<br />',
                    ),
                  }}
                />
                 */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FlexPartnersLookup;
