import sectionOptions from './sectionOptions';

export function flexTiles(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_Tiles {
      backgroundColor
      fieldGroupName
      sectionSlug
      sectionClasses
      tiles {
        fieldGroupName
        headingDate
        link {
          target
          title
          url
        }
        text
      }
      ${sectionOptions}
    }
  `;

  return fragment;
}
