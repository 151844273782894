import sectionOptions from './sectionOptions';

export function flexLanding(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_Landing {
      heading
      content
      formBlurb
      everyactionUrl
      buttonLabel
      ${sectionOptions}
    }
  `;

  return fragment;
}
