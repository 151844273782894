// The current language of the post, along with available
// translation posts in other languages (Polylang Pro)
const postTranslations = /* GraphQL */ `
  language {
    code
    locale
    name
    slug
    homeUrl
  }
  translations {
    id
    title
    slug
    link
    language {
      slug
      name
      code
      locale
    }
  }
`;

export default postTranslations;
