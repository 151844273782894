import cx from 'classnames';
import TranslationsNav from 'components/post/TranslationsNav';
import { LogoHorizontal } from 'components/svg';
import GlobalsContext from 'contexts/GlobalsContext';
import formatMenu from 'lib/formatMenu';
import _get from 'lodash/get';
import Image from 'next/image';
import Link from 'next/link';
import { useState, useEffect, useContext } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdClose } from 'react-icons/md';
import styles from './Header.module.scss';

export default function Header({ variant, translations }) {
  const [navOpen, setNavOpen] = useState(false);
  const globals = useContext(GlobalsContext);

  function handleHamburgerClick(event) {
    setNavOpen(!navOpen);
  }

  function handleClose(event) {
    setNavOpen(false);
  }

  let globalsByLanguage = _get(
    globals,
    'acfThemeSettingsByLanguage.acfOptionsThemeSettingsByLanguage',
  );

  let language = _get(globals, 'language', 'en');

  let alertGroup = {
    link: '',
    text: '',
  };

  if (globalsByLanguage && globalsByLanguage[language]) {
    alertGroup = {
      link: globalsByLanguage[language].alertBarLink,
      text: globalsByLanguage[language].alertBarText,
    };
  }

  let hideNav = globals?.pageOptions?.headerHideNav || false;

  let headerNavArr =
    language == 'es'
      ? globals?.menuHeaderEs?.nodes
      : globals?.menuHeaderEn?.nodes;

  const headerNav = Array.isArray(headerNavArr)
    ? formatMenu(headerNavArr)
    : [];

  function MobileNav() {
    return (
      <div
        className={cx([
          styles.mobileNav,
          {
            // invisible: !navOpen,
            // [styles.isHidden]: !navOpen,
            // visible: navOpen,
            [styles.isVisible]: navOpen,
          },
        ])}
      >
        <a className={styles.close} onClick={handleClose}>
          <MdClose />
        </a>
        <div className={styles.mobileNavInner}>
          <div className="container">
            <div className="row">
              <div className="col-12 text-center text-white">
                {headerNav.length > 0 && (
                  <ul className="list-unstyled">
                    {headerNav.map((item, i) => (
                      <li key={i}>
                        <Link href={item.path} prefetch={false}>
                          <a
                            className={cx(
                              'text-nowrap',
                              styles.parent,
                              item.cssClasses?.map((className) => {
                                if (className == 'btn-red') {
                                  return 'btn-white';
                                }
                                return className;
                              }),
                            )}
                            onClick={handleClose}
                            target={item.target}
                          >
                            <span>{item.label}</span>
                          </a>
                        </Link>
                        {item.children && item.children.length > 0 && (
                          <ul
                            className={cx([
                              styles.dropdown,
                              'list-unstyled',
                            ])}
                          >
                            {item.children.map((item, i) => (
                              <li key={i}>
                                <Link
                                  href={item.path}
                                  prefetch={false}
                                >
                                  <a
                                    className={styles.child}
                                    onClick={handleClose}
                                    target={item.target}
                                  >
                                    {item.label}
                                  </a>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DesktopNav() {
    return (
      <ul className={cx([styles.nav, 'list-inline'])}>
        {headerNav.map((item, i) => (
          <li
            className={cx([
              'list-inline-item',
              { [styles.hasDropdown]: item.children.length > 0 },
            ])}
            key={i}
          >
            <Link href={item.path}>
              <a
                className={cx(
                  styles.parent,
                  item.cssClasses,
                  item.cssClasses?.map((className) => {
                    return styles[className];
                  }),
                )}
                target={item.target}
              >
                <span>{item.label}</span>
                {item.children.length > 0 && (
                  <span className={styles.arrow}>
                    <FaChevronDown />
                  </span>
                )}
              </a>
            </Link>
            {item.children && item.children.length > 0 && (
              <ul
                className={cx([
                  styles.dropdown,
                  'list-unstyled shadow-sm',
                ])}
              >
                {item.children.map((item, i) => (
                  <li key={i}>
                    <Link href={item.path}>
                      <a
                        className={styles.child}
                        target={item.target}
                      >
                        {item.label}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    );
  }

  function AlertContent() {
    return (
      <div
        className={cx(
          styles.alertBarContent,
          'container-fluid text-center',
        )}
      >
        <p>
          <span
            className={styles.alertBarText}
            dangerouslySetInnerHTML={{ __html: alertGroup.text }}
          />
          {alertGroup.link?.title && (
            <>
              <span
                className={cx(
                  styles.alertBarLinkText,
                  'd-inline-block',
                )}
                dangerouslySetInnerHTML={{
                  __html: alertGroup.link.title,
                }}
              />
            </>
          )}
        </p>
      </div>
    );
  }

  return (
    <>
      {alertGroup.text && (
        <>
          {alertGroup.link?.url ? (
            <Link href={alertGroup.link.url}>
              <a
                target={alertGroup.link.target}
                className={cx(
                  'd-block bg-red text-white pt-2 pb-2',
                  styles.alertBarLink,
                )}
              >
                <AlertContent />
              </a>
            </Link>
          ) : (
            <div className="d-block bg-red text-white pt-2 pb-2">
              <AlertContent />
            </div>
          )}
        </>
      )}
      <header className={cx(styles.header)}>
        <div
          className={cx(
            'container text-end mb-3',
            styles.translations,
          )}
        >
          <TranslationsNav
            translations={translations}
            language={language}
          />
        </div>
        <div className="container">
          <div className={cx('row align-items-center')}>
            <div className="col-6 col-lg-2 col-xxl-3">
              <div className={styles.logo}>
                <Link href={language == 'es' ? '/es' : '/'}>
                  <a className={styles.logoLink}>
                    {variant === 'action' ? (
                      <Image
                        src="/wah-action-logo.png"
                        width="200px"
                        height="73.62px"
                      />
                    ) : (
                      <LogoHorizontal
                        role="img"
                        aria-label="We Are Home"
                        title="We Are Home"
                        width="100%"
                      />
                    )}
                  </a>
                </Link>
              </div>
            </div>

            <div className="col col-lg-10 col-xxl-9 text-end">
              {!hideNav && headerNav.length > 0 && (
                <>
                  <div
                    className={cx([
                      'd-lg-none text-end align-self-center',
                      styles.hamburger,
                    ])}
                  >
                    <a onClick={handleHamburgerClick}>
                      <GiHamburgerMenu />
                    </a>
                  </div>
                  <div className={cx(['d-none d-lg-block'])}>
                    <DesktopNav />
                  </div>
                </>
              )}
            </div>
            <MobileNav />
          </div>
        </div>
      </header>
    </>
  );
}
