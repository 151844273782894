import cx from 'classnames';
import LiteYoutubeEmbed from 'components/LiteYouTube';
import Image from 'next/image';
import styles from './FlexImageVideo.module.scss';

const FlexImageVideo = (props) => {
  return (
    <>
      {props.imageOrVideo === 'image' && props.image?.sourceUrl && (
        <div
          className={cx(styles.imageContainer, {
            [styles.imageContainerHero]: props.sectionType === 'hero',
            [styles.eyesonice]: props.eyesonice,
          })}
        >
          <Image
            src={props.image.sourceUrl}
            layout="fill"
            // width={props.image.mediaDetails.width}
            // height={props.image.mediaDetails.height}
            // priority={props.index === 0 || props.index === 1}
          />
          <div className={styles.imageTextContainer}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {props.headingText && (
                    <h1 className={styles.headingText}>
                      <span>{props.headingText}</span>
                    </h1>
                  )}
                  {props.subheadingText && (
                    <div className={styles.subheadingText}>
                      {props.subheadingText}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.imageOrVideo === 'video' &&
        props.video &&
        props.video.includes('youtube.com') &&
        props.video.includes('v=') && (
          <LiteYoutubeEmbed id={props.video.split('v=')[1]} />
        )}
      {props.caption && (
        <div className={styles.caption}>{props.caption}</div>
      )}
    </>
  );
};

export default FlexImageVideo;
