import sectionOptions from './sectionOptions';

export function flexFullWidthImageVideo(template = 'Flex') {
  const fragment = /* GraphQL */ `
    ... on Template_${template}_Acfflex_FlexContent_FullWidthImageVideo {
      caption
      headingText
      image {
        sourceUrl
        mediaDetails {
          width
          height
        }
      }
      imageOrVideo
      sectionType
      video
      ${sectionOptions}
    }
  `;

  return fragment;
}
