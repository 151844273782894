import cx from 'classnames';
import Link from 'next/link';

const Button = ({ color, link, size, text, target, ...props }) => (
  <>
    {link && !target && (
      <Link href={link}>
        <a
          className={cx('btn', {
            [`btn-${color}`]: color,
            [`btn-${size}`]: size,
          })}
          {...props}
        >
          <span>{text}</span>
        </a>
      </Link>
    )}
    {link && target && (
      <a
        href={link}
        target={target}
        rel="noreferrer"
        className={cx('btn', {
          [`btn-${color}`]: color,
          [`btn-${size}`]: size,
        })}
        {...props}
      >
        <span>{text}</span>
      </a>
    )}
    {!link && (
      <button
        className={cx('btn', {
          [`btn-${color}`]: color,
          [`btn-${size}`]: size,
        })}
        {...props}
      >
        <span>{text}</span>
      </button>
    )}
  </>
);

export default Button;
